import React from 'react';
import Header from '../components/header';
import { Grid, makeStyles } from '@material-ui/core';
import { LinedHeader } from '../components/styledComponents';
import Footer from '../components/footer';
import ThemeCollection from '../components/ThemeCollection';

const useStyles = makeStyles((theme) => ({
  treats: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }
}));

const PoojaSweets = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="Pooja Sweets" />
      <Grid container>
        <Grid className={classes.treats} item xs={12}>
          <LinedHeader>POOJA SWEETS</LinedHeader>
          <ThemeCollection
            theme_name=""
            type="sweets"
            items={[
              "barfi3",
              "rasmali1",
              "rasgulla1",
              "coconutbarfi1",
              "gulabjamoon3",
              "diwalibox4",
              "kurma3",
              "coconutbarfi3",
              "diwalibox1",
              "lapsi2",
              "parsad1",
              "roat1",
              "fudge1",
              "rasgulla3",
              "ladoo2",
              "gulabjamoon2",
              "gulabjamoon4",
              "kurma4",
              "ladoo1",
              "kurma5",
              "barfi2",
              "ladoo5",
              "rasmali3",
              "rasgulla4",
            ]}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default PoojaSweets;
